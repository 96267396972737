.logout {
    margin: auto;
    width: 50%;
    padding: 10px;
    padding-bottom:10px;
    position: absolute;
    bottom: 0;
    width: 50%;
    left: 7%;
}

.button-66 {
  background-color: #0cc4d1;
  border-radius: 4px;
  border: 0;
  box-shadow: rgba(9, 134, 128, 0.5) 0 -1px 3px 0 inset,rgba(21, 180, 201, 0.1) 0 3px 6px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inherit;
  font-family: "Space Grotesk",-apple-system,system-ui,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
  min-height: 56px;
  min-width: 120px;
  padding: 16px 20px;
  position: relative;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  transition: all .2s cubic-bezier(.22, .61, .36, 1);
}

.button-66:hover {
  background-color: #118da3;
  transform: translateY(-2px);
}

@media (min-width: 768px) {
  .button-66 {
    padding: 16px 44px;
    min-width: 200px;
  }
}



/* CSS */
.button-1 {
  background-color: #EA4C89;
  border-radius: 5px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  min-height: 56px;
  min-width: 200px;
  padding: 16px 20px;
  line-height: 20px;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  justify-content: center;
}

.button-1:hover,
.button-1:focus {
  background-color: #F082AC;
}